import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"text":"","loading":_vm.loading,"disabled":_vm.loading}},on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-earth")]),_c('span',[_vm._v(_vm._s(_vm.isEN ? "English" : "Deutsch"))]),_c(VIcon,{attrs:{"right":""}},[_vm._v(_vm._s(_vm.icon))])],1)]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c(VList,[_c(VListItemGroup,{model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}},[_c(VListItem,{attrs:{"value":"en"},on:{"click":_vm.setEN}},[_c('span',[_vm._v("English")])]),_c(VListItem,{attrs:{"value":"de"},on:{"click":_vm.setDE}},[_c('span',[_vm._v("Deutsch")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }