











































































































import Vue from "vue";
import SingleWord from "./SingleWord.vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { LookupWord, LookupWordType } from "@/core/models/sessions";

@Component({ components: { SingleWord } })
export default class WordSearch extends Vue {
  @Prop({ default: () => [] }) lookupWords!: LookupWord[];

  filter = "";
  exact = false;
  showFull = false;
  showButton = true;
  ignoreTypes: LookupWordType[] = [];
  toggleType(type: LookupWordType) {
    if (this.ignoreTypes.includes(type))
      this.ignoreTypes = this.ignoreTypes.filter(x => x !== type);
    else this.ignoreTypes.push(type);
  }
  get filterText() {
    const start = this.$t("lookup.start");
    const ftext = this.$t("lookup.filter");
    if (!this.filter) return start;
    return `${ftext} ${this.filter}`;
  }
  get words() {
    const allWords = this.lookupWords.filter(
      x => !this.ignoreTypes.includes(x.type),
    );
    const filtered = this.filter
      ? this.exact
        ? allWords.filter(x => x.text === this.filter)
        : allWords.filter(x => x.text.toLowerCase().includes(this.filter))
      : allWords;

    if (filtered.length < 15) this.showButton = false;
    else this.showButton = true;
    return this.showFull ? filtered : filtered.slice(0, 15);
  }

  get hasDefault() {
    const type = LookupWordType.default;
    return (
      this.words.some(x => x.type === type) || this.ignoreTypes.includes(type)
    );
  }
  get hasStop() {
    const type = LookupWordType.stop;
    return (
      this.words.some(x => x.type === type) || this.ignoreTypes.includes(type)
    );
  }
  get hasFiller() {
    const type = LookupWordType.filler;
    return (
      this.words.some(x => x.type === type) || this.ignoreTypes.includes(type)
    );
  }

  @Watch("lookupWords", { immediate: true })
  wordsChanged() {
    this.showButton = true;
  }
}
