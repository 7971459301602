




















































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

const placeholderImage =
  "https://www.geographicexperiences.com/wp-content/uploads/revslider/home5/placeholder-1200x500.png";
@Component
export default class AddGoalButton extends Vue {
  @Prop({ default: () => 0 }) groupId!: number;
  @Getter("groups/addPostLoading") addPostLoading!: boolean;
  @Action("groups/addPost") addPostAction!: Function;

  body = "";
  title = "";
  isPinned = false;
  sendEmail = false;
  includeInvited = false;
  showDialog = false;
  files: File[] = [];
  hasBannerImage = false;
  imagePreview: any = placeholderImage;
  bannerImage: File | null = null;

  titleRules = [
    (title: string) => {
      const msg = this.$t("forms.reqfield").toString();
      if (!title) return msg;
      if (title.trim().length == 0) return msg;
      else return true;
    },
  ];
  bodyRules = [
    (body: string) => {
      const msg = this.$t("forms.reqfield").toString();
      if (!body) return msg;
      if (body.trim().length == 0) return msg;
      else return true;
    },
  ];
  sendEmailRules = [
    (sendEmail: boolean) => {
      console.log("validation. sendEmail", sendEmail);
      console.log("validation. sendEmail", this.sendEmailProp);
      console.log("validation. hasBannerImage", this.hasBannerImage);
      console.log("validation. files", this.files.length);
      const msg =
        "Sie können keine Posts mit Banner oder angehängten Dateien per Email versenden.";
      if (sendEmail && (this.hasBannerImage || this.files.length > 0))
        return msg;
      return true;
    },
  ];
  includeInvitedRules = [
    (includeInvited: boolean) => {
      const msg =
        "Um einen Post an eingeladene Gruppenmitglieder zu senden, müssen Sie auch den Schalter für den E-Mail Versand aktivieren.";
      if (includeInvited && !this.sendEmail) return msg;
      return true;
    },
  ];

  get sendEmailProp() {
    return this.sendEmail;
  }

  get canSubmit() {
    return (
      this.titleRules[0](this.title) == true &&
      this.bodyRules[0](this.body) === true &&
      !this.validationErrorSendEmail &&
      !this.validationErrorIncludeInvited
    );
  }

  get validationErrorIncludeInvited() {
    return this.includeInvited && !this.sendEmail;
  }

  get validationErrorSendEmail() {
    return this.sendEmail && (this.hasBannerImage || this.files.length > 0);
  }

  toggleDialog() {
    this.showDialog = !this.showDialog;
    this.title = "";
    this.body = "";
    this.isPinned = false;
    this.sendEmail = false;
    this.includeInvited = false;
    this.imagePreview = placeholderImage;
    this.bannerImage = null;
    this.files = [];

    if (this.$refs.form)
      // @ts-ignore
      this.$refs.form.resetValidation();
  }
  previewImage() {
    if (!this.bannerImage) {
      this.imagePreview = placeholderImage;
      return;
    }
    const reader = new FileReader();
    reader.onload = () => (this.imagePreview = reader.result);
    reader.readAsDataURL(this.bannerImage);
  }
  addPost() {
    this.title = this.title.trim();
    this.body = this.body.trim();

    if (this.addPostLoading) return;
    if (this.title.length == 0 || this.body.length == 0) return;
    if (this.hasBannerImage && !this.bannerImage) return;
    if (this.includeInvited && !this.sendEmail) return;
    if (this.sendEmail && (this.hasBannerImage || this.files.length > 0))
      return;

    const data = new FormData();
    data.append("groupId", this.groupId.toString());
    data.append("title", this.title);
    data.append("body", this.body);
    data.append("isPinned", "" + this.isPinned);
    if (this.hasBannerImage) data.append("bannerImage", this.bannerImage!);
    this.files.forEach(file => data.append("files", file));
    data.append("sendEmail", "" + this.sendEmail);
    data.append("includeInvited", "" + this.includeInvited);

    this.addPostAction(data);
    this.toggleDialog();
  }
}
