



















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class VolumeCircle extends Vue {
  @Prop({ default: () => 0 }) volume!: number;
  @Prop({ default: () => false }) isTesting!: boolean;
  @Prop({ default: () => true }) canToggle!: boolean;

  get style() {
    let scale = this.volume / 100 + 1;
    if (scale > 1.5) scale = 1.5;
    const str = `transform: scale(${scale})`;
    return str;
  }
}
