









import Vue from "vue";
import { FileObject } from "@/core/models";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class GroupPostItem extends Vue {
  @Prop({ default: () => {} }) file!: FileObject;

  get size() {
    let size = this.file.Size / Math.pow(2, 20);
    let suffix = "MB";
    if (size < 1) {
      size = this.file.Size / Math.pow(2, 10);
      suffix = "KB";
    }
    size = Math.round(size * 100) / 100;
    return size + suffix;
  }
  openFile() {
    window.open(this.file.URI, "_blank");
  }
}
