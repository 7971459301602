






















import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component
export default class LangSwitcher extends Vue {
  @Prop({ default: () => false }) loading!: boolean;
  @PropSync("recorderLocale") locale!: "en" | "de";
  menuOpen = false;

  lang = this.locale || "";
  get isDE() {
    return this.locale === "de";
  }
  get isEN() {
    return this.locale === "en";
  }
  get icon() {
    return this.menuOpen ? "mdi-chevron-up" : "mdi-chevron-down";
  }
  setDE() {
    this.locale = "de";
  }
  setEN() {
    this.locale = "en";
  }
  created() {
    this.lang = this.locale;
  }
}
