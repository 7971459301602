import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.videos && _vm.videos.length > 0)?_c('div',{staticClass:"pa-2 pt-0"},[_c(VRow,_vm._l((_vm.pages.CurrentPage),function(item,i){return _c(VCol,{key:i,attrs:{"cols":"12","md":12 / _vm.limit}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"pa-2",class:{
              lightgray: hover,
              lightgray2: item.ID === _vm.group.PinnedVideoID,
            },attrs:{"hover":"","elevation":"0"}},[_c('VideoPlayer',{key:i,attrs:{"id":i,"url":item.File.URI}}),_c('div',{staticClass:"pt-4 main--text d-flex"},[_c('div',{staticClass:"flex-1 headline font-weight-bold text-truncate"},[(item.ID === _vm.group.PinnedVideoID)?_c('span',{staticClass:"mr-2"},[_c(VIcon,[_vm._v("mdi-pin")])],1):_vm._e(),_c('span',[_vm._v(_vm._s(item.Title))])]),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectVideo(item)}}},[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)],1),_c('div',{staticClass:"subtitle-1 main--text text-truncate"},[_vm._v(" "+_vm._s(item.Description.replaceAll( "Uploaded by", _vm.$t("groups.uploaded_by").toString() ))+" ")])],1)]}}],null,true)})],1)}),1),_c('PagesRender',{attrs:{"pages":_vm.pages}})],1):_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("groups.novideos")))])]),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.video)?_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"flex-1 mr-4"},[_vm._v(_vm._s(_vm.video.Title))]),(_vm.isManager)?_c('VideoMenu',{attrs:{"pinned-video-id":_vm.video.PinnedVideoID,"dialog":_vm.dialog,"video":_vm.video},on:{"update:dialog":function($event){_vm.dialog=$event}}}):_vm._e()],1),_c(VCardText,_vm._l((_vm.video.Description.split('\n')),function(p,i){return _c('div',{key:i,staticClass:"mb-2",staticStyle:{"width":"100%"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.parseParagraph(p))}})])}),0)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }