










import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class InformationTooltip extends Vue {
  @Prop({ default: () => "" }) text!: string;
  @Prop({ default: () => "" }) message!: string;
  @Prop({ default: () => false }) bottom!: boolean;

  get tooltipText(): string {
    if (this.text !== "") return this.$t(this.text).toString();
    else return this.message;
  }
}
