class Pages<T> {
  private Limit: number;
  public CurrentPageId: number;
  public CurrentPage: T[];
  public NumPages: number;
  public Pages: T[][];

  constructor(limit: number, content: T[]) {
    this.Pages = [];
    this.Limit = limit;
    if (content.length > 0) {
      this.makePages(content);
      this.NumPages = Math.ceil(content.length / this.Limit);
      this.CurrentPageId = 0;
      this.CurrentPage = this.Pages[this.CurrentPageId];
    } else {
      this.NumPages = 0;
      this.CurrentPageId = 0;
      this.CurrentPage = [];
    }
  }

  public setPage(id: number) {
    if (id > this.NumPages - 1 || id < 0) return;
    this.CurrentPageId = id;
    this.CurrentPage = this.Pages[this.CurrentPageId];
  }

  private makePages(content: T[]) {
    for (let i = 0; i < content.length; i += this.Limit)
      this.Pages.push(content.slice(i, i + this.Limit));
  }

  public static Empty() {
    return new Pages<any>(0, []);
  }
}

export default Pages;
