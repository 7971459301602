

















import Vue from "vue";
import { Getter } from "vuex-class";
import AddVideoButton from "./AddVideoButton.vue";
import { Group } from "@/core/models";
import { Component, Prop } from "vue-property-decorator";
import VideosList from "@/components/common/VideosList.vue";

@Component({ components: { AddVideoButton, VideosList } })
export default class GroupVideosCard extends Vue {
  @Prop({ default: () => {} }) group!: Group;
  @Getter("groups/addVideoLoading") addVideoLoading!: boolean;
  get groupId() {
    return this.group.ID;
  }
  get isManager() {
    return this.group.IsManager;
  }
}
