















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ProgressFill extends Vue {
  @Prop({ default: () => 0 }) progress!: number;
  @Prop({ default: () => 100 }) size!: number;

  get height() {
    return this.size + "px";
  }
  get width() {
    return this.size + "px";
  }
  get fontSize() {
    return this.size * 0.3 + "px";
  }
  get color() {
    if (this.progress > 50) return "white";
    else return "black";
  }
}
