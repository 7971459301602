

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Gauge from "./Gauge.vue";
import Recorder from "../../lib/recorder";
import VolumeCircle from "./VolumeCircle.vue";

@Component({ components: { Gauge, VolumeCircle } })
export default class VolumeTester extends Vue {
  @Prop({ default: () => "gauge" }) type!: "gauge" | "circle";

  volume = 0;
  isTesting = false;
  tester: Recorder | null = null;
  async toggleTest() {
    if (!this.tester) return;
    if (this.isTesting) {
      await this.tester.stop();
      this.volume = 0;
    } else await this.tester.start();
    this.isTesting = !this.isTesting;
  }
  mounted() {
    this.tester = new Recorder(({ vol }) => (this.volume = vol));
  }
  beforeDestroy() {
    this.stop();
    this.tester = null;
  }

  async stop() {
    if (!this.tester) return;
    await this.tester.cancel();
    this.isTesting = false;
    this.volume = 0;
  }

  async start() {
    if (!this.tester) return;
    await this.tester.start();
    this.isTesting = true;
    this.volume = 0;
  }
}
