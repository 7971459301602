





































import Vue from "vue";
import api from "@/core/utils/api";
import { SentInvitation } from "@/core/models";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component
export default class InvitedUsers extends Vue {
  @Prop({ default: () => 0 }) groupId!: number;
  @PropSync("invitationsProp", { default: () => [] })
  invitations!: SentInvitation[];

  term = "";
  loading = false;
  searching = false;

  get filtered() {
    if (!this.term) return this.invitations;
    return this.invitations.filter(x => x.user.includes(this.term));
  }

  async get() {
    this.loading = true;
    try {
      const end = `/api/Groups/InvitationsSent/${this.groupId}`;
      this.invitations = (await api.get(end)) as SentInvitation[];
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  async remove(inv: SentInvitation) {
    this.loading = true;
    try {
      const end = `/api/Groups/DeleteInvitaiton/${inv.id}`;
      await api.delete(end);
      const idx = this.invitations.findIndex(x => x.id == inv.id);
      if (idx != -1) this.invitations.splice(idx, 1);
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  mounted() {
    this.get();
  }
}
