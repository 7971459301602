

















































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { Group } from "@/core/models";

import { DisplayError } from "@/components/common";
import { GroupItem, AddGroupCard } from "../components";
import { hasPermissions } from "@/core/utils/auth";

@Component({
  methods: { hasPermissions },
  components: {
    DisplayError,
    GroupItem,
    AddGroupCard,
  },
})
export default class MyGroups extends Vue {
  @Getter("groups/loadingGroups") loadingGroups!: boolean;
  @Getter("groups/getGroups") groups!: Group[];
  @Getter("groups/retrievedGroups") retrieved!: boolean;
  @Action("groups/getGroups") getGroups!: () => Promise<void>;

  created() {
    if (!this.retrieved) this.getGroups();
  }

  get() {
    this.getGroups();
  }
}
