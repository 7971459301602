
























import Vue from "vue";
import { GroupUser } from "@/core/models";
import GroupUsersList from "./GroupUsersList.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({ components: { GroupUsersList } })
export default class GroupUsersCard extends Vue {
  @Prop({ default: () => -1 }) groupId!: number;
  @Prop({ default: () => false }) isManager!: boolean;
  @Prop({ default: () => [] as GroupUser[] }) allUsers!: GroupUser[];

  filterTerm = "";
  searching = false;

  // Helpers
  get filteredUsers() {
    return this.allUsers.filter(u => u.Email.includes(this.filterTerm));
  }
  get users() {
    return this.filteredUsers.filter(u => !u.IsOwner);
  }
  get owner() {
    return this.allUsers.find(u => u.IsOwner == true)!;
  }

  // User search
  toggleSearch() {
    if (this.searching) {
      this.searching = false;
      this.filterTerm = "";
    } else this.searching = true;
  }
  filterResults(newTerm: string) {
    this.filterTerm = newTerm;
  }
}
