<template>
  <v-dialog v-model="display" width="400px">
    <template #activator="{ on }">
      <v-text-field
        :value="momentFormatted"
        :error-messages="error"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        readonly
        v-on="on"
      >
        <template #progress>
          <v-progress-linear
            color="primary"
            indeterminate
            absolute
            height="2"
          />
        </template>
      </v-text-field>
    </template>

    <v-card elevation="0">
      <v-card-text class="px-0 py-0">
        <v-tabs v-model="activeTab" fixed-tabs>
          <v-tab key="calendar">
            <v-icon>mdi-calendar</v-icon>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <v-icon>mdi-clock</v-icon>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              color="primary"
              :locale="$i18n.locale"
              :min="
                (minDate && minDate.toISOString()) ||
                new Date(Date.now()).toISOString()
              "
              full-width
              @input="showTimePicker"
            />
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              v-model="time"
              class="v-time-picker-custom"
              format="24hr"
              color="primary"
              full-width
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="accent lighten-1" text @click.native="clearHandler">
          <span>Clear</span>
        </v-btn>
        <v-btn color="primary darken-1" text @click="okHandler">Set</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";
import { format, parse } from "date-fns";

const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00:00";
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";

export default {
  model: { prop: "datetime", event: "input" },
  props: {
    datetime: { type: [Date, String], default: null },
    disabled: { type: Boolean },
    loading: { type: Boolean },
    label: { type: String, default: () => "" },
    minDate: { type: Date, default: () => "" },
    error: { type: [String, Array], default: () => ["", []] },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
    };
  },
  computed: {
    momentFormatted() {
      const date = this.selectedDatetime;
      if (!date) return;
      return DateTime.fromJSDate(date).toLocaleString({
        locale: this.$i18n.locale,
      });
    },
    defaultDateTimeFormat() {
      return DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT;
    },
    selectedDatetime() {
      if (!this.date || !this.time) return null;
      let datetimeString = this.date + " " + this.time;
      if (this.time.length === 5) datetimeString += ":00";
      return parse(datetimeString, this.defaultDateTimeFormat, new Date());
    },
    dateSelected() {
      return !this.date;
    },
  },
  watch: {
    datetime: function () {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.datetime) return;

      let initDateTime = this.datetime;
      if (typeof this.datetime === "string" || this.datetime instanceof String)
        initDateTime = parse(
          this.datetime,
          this.defaultDateTimeFormat,
          new Date(),
        );

      this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
      this.time = format(initDateTime, DEFAULT_TIME_FORMAT);
    },
    okHandler() {
      this.resetPicker();
      this.$emit("input", this.selectedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit("input", null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) this.$refs.timer.selectingHour = true;
    },
    showTimePicker() {
      this.activeTab = 1;
    },
  },
};
</script>
