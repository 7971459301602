




















































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Recorder from "@/core/utils/videoRecorder";
import DeviceSelect from "@/views/recorder/components/DeviceSelect.vue";
import { Action } from "vuex-class";

@Component({ components: { DeviceSelect } })
export default class VideoRecorderWithBottomToolbar extends Vue {
  @Prop({ default: () => false }) isTimed!: boolean;
  @Prop({ default: () => 0 }) expectedLen!: number;
  @Prop({ default: () => 0 }) maxLen!: number;
  @Prop({ default: () => false }) loading!: boolean;
  @Prop({ default: () => true }) canShare!: boolean;
  @Prop({ default: () => true }) canAskForFeedback!: boolean;
  @Prop({ default: () => false }) defaultShared!: boolean;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  recorder: Recorder = new Recorder();
  video: HTMLVideoElement | undefined;
  blob: Blob | undefined;
  isRecording = false;
  isPaused = false;
  isActive = false;
  flipped = false;

  // time controls
  elapsedTime = 0;
  interval: any = null;
  timeout: any = null;

  startTimeout() {
    this.timeout = setTimeout(
      this.stop,
      (this.maxLen - this.elapsedTime) * 1000,
    );
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.elapsedTime += 1;
    }, 1 * 1000);
  }

  stopTimeout() {
    clearTimeout(this.timeout);
  }

  stopTimer() {
    clearInterval(this.interval);
  }

  async start() {
    if (!this.video) return;

    const stream = await this.recorder.start(
      {
        onError: (errorMessage: string) => this.displaySnackbar(errorMessage),
      },
      true,
    );
    if (!stream) return;
    this.video.removeAttribute("src");
    this.video.srcObject = stream;
    this.video.play();
    this.video.muted = true;
    this.video.controls = false;
    this.isRecording = true;
    this.isActive = true;

    if (this.isTimed) this.startTimeout();
    this.startTimer();
  }

  pause() {
    this.recorder.pause();
    this.isPaused = true;
    this.stopTimer();
    if (this.isTimed) this.stopTimeout();
  }

  resume() {
    this.recorder.resume();
    this.isPaused = false;
    this.startTimer();
    if (this.isTimed) this.startTimeout();
  }

  stop() {
    this.recorder.stop().then(blob => {
      if (!this.video || !blob) return;
      this.blob = blob.videoBlob;
      this.video.muted = false;
      this.video.srcObject = null;
      this.video.controls = true;
      this.video.src = URL.createObjectURL(this.blob);
      this.isRecording = false;
      this.isPaused = false;

      if (this.isTimed) this.stopTimeout();
      this.stopTimer();
      this.elapsedTime = 0;
    });
  }

  reset() {
    this.recorder.stop();
    this.recorder.reset();
    this.isActive = false;
    if (!this.video) return;
    this.video.muted = true;
    this.video.pause();
    this.video.currentTime = 0;
    this.video.removeAttribute("src");
    this.video.srcObject = null;
    this.isRecording = false;
    this.elapsedTime = 0;
    this.stopTimer();
    if (this.isTimed) this.stopTimeout();
  }

  uploadDialog = false;

  openUpload() {
    this.uploadDialog = true;
  }

  upload() {
    if (!this.blob) return;
    this.$emit("upload", {
      blob: this.blob,
    });
    this.cancelUpload();
  }

  cancelUpload() {
    this.uploadDialog = false;
    this.reset();
  }

  mounted() {
    this.video = document.getElementById("video") as HTMLVideoElement;
  }
}
