import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VOverlay,{staticClass:"d-flex align-center justify-center",attrs:{"absolute":"","value":(_vm.deleteVideoLoading && _vm.deletedVideoID == _vm.video.ID) ||
      (_vm.editVideoLoading && _vm.editedVideoID == _vm.video.ID)}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){_vm.editDialog = true}}},[_c(VListItemSubtitle,{attrs:{"disabled":_vm.editVideoLoading}},[_c(VIcon,{staticClass:"mr-2",attrs:{"left":"","small":""}},[_vm._v("mdi-pencil")]),_c('span',[_vm._v(_vm._s(_vm.$t("groups.editv")))])],1)],1),_c(VListItem,{attrs:{"disabled":_vm.deleteVideoLoading},on:{"click":_vm.deleteVideo}},[_c(VListItemSubtitle,[_c(VIcon,{staticClass:"mr-2",attrs:{"left":"","small":""}},[_vm._v("mdi-delete")]),_c('span',[_vm._v(_vm._s(_vm.$t("groups.delv")))])],1)],1)],1)],1),_c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex align-center",staticStyle:{"justify-content":"space-between"}},[_c('span',[_vm._v(_vm._s(_vm.$t("groups.editv"))+" #"+_vm._s(_vm.video.ID))]),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.editDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.editVideo($event)}}},[_c(VTextField,{attrs:{"label":_vm.$t('groups.vidtitlelabel'),"error-messages":_vm.titleErrors},model:{value:(_vm.titleEdit),callback:function ($$v) {_vm.titleEdit=$$v},expression:"titleEdit"}}),_c(VTextarea,{attrs:{"label":_vm.$t('groups.viddesc'),"clearable":"","auto-grow":"","rows":"4"},model:{value:(_vm.descriptionEdit),callback:function ($$v) {_vm.descriptionEdit=$$v},expression:"descriptionEdit"}}),_c(VSwitch,{attrs:{"label":_vm.$t('groups.vidpinlabel')},model:{value:(_vm.isPinnedEdit),callback:function ($$v) {_vm.isPinnedEdit=$$v},expression:"isPinnedEdit"}}),_c(VBtn,{attrs:{"block":"","elevation":"0","type":"submit","color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.done")))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }