

































import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component
export default class NumberSelect extends Vue {
  @Prop({ default: () => 0 }) min!: number;
  @Prop({ default: () => 200 }) max!: number;
  @PropSync("number", { type: Number }) numberProp!: number;

  numberRules = [
    (v: number) => v >= this.min || this.$t("number.toosmall", { n: this.min }),
    (v: number) => v <= this.max || this.$t("number.toobig", { n: this.max }),
  ];

  toggleCustom = false;
  get canInc() {
    return this.numberProp < this.max;
  }
  inc() {
    if (this.canInc) this.numberProp++;
  }
  get canDec() {
    return this.numberProp > 0;
  }
  dec() {
    if (this.canDec) this.numberProp--;
  }
  changeCustom(input: number) {
    let val = input.toString();
    const num = Number(input);
    if (isNaN(num)) this.numberProp = 0;
    else if (num < this.min) val = this.min.toString();
    else if (num > this.max) val = this.max.toString();
    const forbidden = ["e", "-", ","];
    forbidden.forEach(x => val.replaceAll(x, ""));
    this.numberProp = Number(val);
  }
}
