import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"top":"","max-width":"1000px","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.getVideo}},on),[_c(VIcon,[_vm._v("mdi-video-outline")])],1)]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.video)?_c(VCard,[_c(VCardTitle,{staticClass:"d-flex"},[_c('div',{staticClass:"flex-1 mr-4"},[_vm._v(_vm._s(_vm.video.title))]),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.open = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('VideoPlayer',{attrs:{"id":("how-to-video-" + _vm.id),"url":_vm.video.video.uri,"should-play":_vm.shouldPlay,"should-stop":_vm.shouldStop},on:{"update:shouldPlay":function($event){_vm.shouldPlay=$event},"update:should-play":function($event){_vm.shouldPlay=$event},"update:shouldStop":function($event){_vm.shouldStop=$event},"update:should-stop":function($event){_vm.shouldStop=$event}}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }