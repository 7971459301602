import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-6",attrs:{"elevation":"0"}},[_c(VSubheader,[_c('span',[_vm._v(_vm._s(_vm.$t("groups.addnu")))]),_c(VSpacer),(_vm.adding)?_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.toggleAddUser}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()],1),_c('div',{staticClass:"pa-4"},[(_vm.adding)?_c(VForm,{staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.submitAddUser($event)}}},[_c('EmailsInput',{attrs:{"label":_vm.$t('addGroupCard.users'),"next-user-prop":_vm.nextUser,"emails-prop":_vm.newUsers},on:{"update:nextUserProp":function($event){_vm.nextUser=$event},"update:next-user-prop":function($event){_vm.nextUser=$event},"update:emailsProp":function($event){_vm.newUsers=$event},"update:emails-prop":function($event){_vm.newUsers=$event}}}),_c(VBtn,{staticClass:"mt-2",attrs:{"disabled":_vm.addUsersLoading,"loading":_vm.addUsersLoading,"color":"primary","elevation":"0","type":"submit","block":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.submit")))])])],1):_vm._e(),(!_vm.adding)?_c(VBtn,{attrs:{"elevation":"0","color":"primary","block":""},on:{"click":_vm.toggleAddUser}},[_c('span',[_vm._v(_vm._s(_vm.$t("groups.addu")))])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }