
























































import Vue from "vue";
import { GroupVideo } from "@/core/models";
import GroupVideoMenu from "./GroupVideoMenu.vue";
import { VideoPlayer } from "@/components/common";
import { Component, Prop } from "vue-property-decorator";

const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
@Component({ components: { GroupVideoMenu, VideoPlayer } })
export default class GroupVideoItem extends Vue {
  @Prop({ default: () => 0 }) groupId!: number;
  @Prop({ default: () => false }) isPinned!: boolean;
  @Prop({ default: () => false }) isManager!: boolean;
  @Prop({ default: () => ({} as GroupVideo) }) video!: GroupVideo;

  playDialog = false;
  parseParagraph(p: string) {
    let matches = p.matchAll(urlRegex);
    for (let match of matches) {
      let url = match[0];
      let href = url;
      if (!url.startsWith("http")) href = "https://" + url;
      if (url.includes("@"))
        href = "https://mail.google.com/mail/?view=cm&fs=1&to=" + url;
      let tag = `<a href="${href}" target="_blank">${url}</a>`;
      p = p.replace(url, tag);
    }
    return p;
  }
}
