




























import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";

const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
@Component({})
export default class EmailsInput extends Vue {
  @Prop({ default: () => "Emails" }) label!: any;
  @PropSync("nextUserProp", { type: String }) nextUser!: string;
  @PropSync("emailsProp", { type: Array }) emails!: string[];

  chipRules = [
    (chips: string[]) => {
      if (chips.some(c => c.length < 3)) return true;
      if (chips.some(c => !pattern.test(c))) return "Invalid email";
      else return true;
    },
  ];
}
