



































import Vue from "vue";
import { Component } from "vue-property-decorator";

import api from "@/core/utils/api";
import { GroupInvitation } from "@/core/models";
import SingleInvitation from "./SingleInvitation.vue";

@Component({ components: { SingleInvitation } })
export default class GroupInvitations extends Vue {
  loading = false;
  invitations: GroupInvitation[] = [];
  get has() {
    return this.invitations.length > 0;
  }

  async get() {
    this.loading = true;
    try {
      const end = "/api/Groups/Invitations";
      this.invitations = (await api.get(end)) as GroupInvitation[];
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  remove(i: number) {
    this.invitations.splice(i, 1);
  }

  mounted() {
    this.get();
  }
}
