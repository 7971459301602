

































































































































































import Vue from "vue";
import { Group } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class GroupItem extends Vue {
  @Prop({ default: () => {} }) group!: Group;
  @Getter("groups/removeGroupLoading") removeGroupLoading!: boolean;
  @Getter("groups/removedGroupID") removedGroupID!: number;
  @Action("groups/removeGroup") removeGroupAction!: Function;
  @Action("groups/leaveGroup")
  leaveGroupAction!: (i: number) => Promise<void>;
  @Action("displaySnackbar")
  displaySnackbar!: (m: string) => void;

  leaveDialog = false;
  removeDialog = false;
  leaving = false;
  maxGroupUsersAvatars = 3;

  // Group helpers
  get userEmails() {
    let emails = this.group.Users.map(u => u.Email);
    if (emails.length > 3) return emails.slice(0, 3).join(", ") + ", ...";
    else return emails.join(", ");
  }

  get userAvatars() {
    const users = this.group.Users.map(u => this.getInitials(u.Username));
    //const users = this.fakeUsers;

    return users.slice(0, this.maxGroupUsersAvatars);
  }

  // Test with many users: Uncomment in userAvatars() and replace group.Users.length with fakeUsers.length in <v-avatar> above
  /*get fakeUsers() {
    let users = [];
    for (let i = 0; i < 999999 + this.maxGroupUsersAvatars; i++)
      users.push(this.getInitials("Jane Doe"));

    return users;
  }*/

  getInitials(fullName: string) {
    const allNames = fullName.trim().split(" ");
    return allNames.reduce((acc: string, curr: string, index: number) => {
      if (index === 0 || index === allNames.length - 1)
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;

      return acc;
    }, "");
  }

  getMoreUserCounter(usersCount: number) {
    const counter = usersCount - this.maxGroupUsersAvatars;
    // Note: We always use en for compact formatting, de-DE doesn't support short formats like 1K or 1M
    if (counter > 100000 && counter < 1000000)
      return this.$n(counter, "compact", "en");

    return this.$n(counter, "compactFraction1", "en");
  }

  get owner() {
    const user = this.group.Users.find(x => x.IsOwner);
    return user ? user.Username : "";
  }

  goToDetails() {
    this.$router.push("/groups/" + this.group.ID);
  }

  // Delete group
  removeGroup() {
    if (this.removeGroupLoading) return;
    this.removeDialog = false;
    this.removeGroupAction(this.group.ID);
  }

  // leave group
  async leaveGroup() {
    this.leaving = true;
    try {
      const msg = this.$t("groupItem.groupLeft", {
        name: this.group.Name,
      }).toString();
      await this.leaveGroupAction(this.group.ID);
      this.displaySnackbar(msg);
    } catch (error) {
      console.log(error);
    }
    this.leaveDialog = false;
    this.leaving = false;
  }
}
