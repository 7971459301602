








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class DisplayError extends Vue {
  @Prop({ default: () => null }) error!: any;
}
