import Recorder from "recorder-js";

import { Recording } from "@/core/models";
import i18n from "@/core/plugins/i18n";
import { getInputDevice } from "./devices";

type Status = {
  vol: number;
  duration: number;
};
export default class {
  interval: any;
  muted = false;
  prevGain = 1;
  isRecording = false;
  recorder?: Recorder;
  onupdate: (status: Status) => void;

  gainNode?: GainNode;
  stream?: MediaStream;
  context?: AudioContext;
  analyzer?: AnalyserNode;
  src?: MediaStreamAudioSourceNode;
  dest?: MediaStreamAudioDestinationNode;

  constructor(onupdate: (status: Status) => void = () => {}) {
    this.onupdate = onupdate;
  }

  async start() {
    const inputDevice = getInputDevice();
    const constraints: MediaStreamConstraints = {
      video: false,
      audio: {
        channelCount: 1,
        echoCancellation: false,
        deviceId: inputDevice ? { exact: inputDevice } : undefined,
      },
    };

    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.stream = stream;
      this._micCaptured(stream);
      return true;
    } catch (error) {
      this.stream?.getTracks().forEach(t => t.stop());
      console.log("Mic error", error);
      return false;
    }
  }

  async stop() {
    const res = await this.recorder?.stop();
    await this.stopAudio();
    this.isRecording = false;

    if (!res) return null;

    const { blob } = res;
    const now = new Date();
    const record = {
      blob,
      recordedAt: now.toISOString(),
      title: `${i18n.t("rec.prefix")} ${now.toLocaleString()}`,
    } as Recording;
    return record;
  }

  async cancel() {
    await this.recorder?.stop();
    await this.stopAudio();
  }

  async stopAudio() {
    clearInterval(this.interval);
    this.stream?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    this.src?.disconnect();
    this.analyzer?.disconnect();
    await this.context?.close();
  }

  toggleMuted() {
    // todo - transcription must be paused, not stopped
    if (this.muted)
      this.gainNode?.gain.setValueAtTime(
        this.prevGain,
        this.context?.currentTime || 0,
      );
    else this.gainNode?.gain.setValueAtTime(0, this.context?.currentTime || 0);
    this.muted = !this.muted;
  }

  setVolume(value: number) {
    if (value == 0) this.toggleMuted();
    else {
      this.gainNode?.gain.setValueAtTime(value, this.context?.currentTime || 0);
      this.prevGain = value;
    }
  }

  async _micCaptured(stream: MediaStream) {
    // @ts-ignore
    this.context = new (window.AudioContext || window.webkitAudioContext)();
    this.analyzer = this.context.createAnalyser();
    this.src = this.context.createMediaStreamSource(stream);
    this.dest = this.context.createMediaStreamDestination();
    this.gainNode = this.context.createGain();

    this.analyzer.fftSize = 256;
    this.src.connect(this.analyzer);
    this.analyzer.connect(this.gainNode);
    this.gainNode.connect(this.dest);

    this.interval = setInterval(() => {
      if (!this.analyzer) return;
      const buffer = new Uint8Array(this.analyzer.frequencyBinCount);
      this.analyzer.getByteFrequencyData(buffer);
      const sum = buffer.reduce((cum, curr) => cum + curr, 0);
      const vol = sum / buffer.length;
      const duration = this.context?.currentTime || 0;
      this.onupdate({ vol, duration });
    }, 50);

    this.recorder = new Recorder(this.context);
    await this.recorder.init(this.dest.stream);
    await this.recorder.start();
    this.isRecording = true;
  }
}
