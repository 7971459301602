









































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { EmailsInput } from "@/components/common";
import { Component, Prop } from "vue-property-decorator";
import { GroupUser, SentInvitation } from "@/core/models";
import { InviteUsersData } from "@/core/store/groups/users/actions";

@Component({ components: { EmailsInput } })
export default class AddUserCard extends Vue {
  @Prop({ default: () => -1 }) groupId!: number;
  @Prop({ default: () => [] }) users!: GroupUser[];

  @Getter("groups/addUsersLoading") addUsersLoading!: boolean;
  @Getter("groups/renameGroupLoading") renameGroupLoading!: boolean;
  @Action("groups/inviteUsers")
  inviteUsersAction!: (data: InviteUsersData) => Promise<SentInvitation[]>;
  @Action("displaySnackbar") displaySnackbar!: Function;

  adding = false;
  nextUser = "";
  newUsers: string[] = [];

  toggleAddUser() {
    this.adding = !this.adding;
  }
  submitAddUser() {
    if (this.nextUser) this.newUsers.push(this.nextUser);
    if (this.newUsers.length == 0) {
      const msg = this.$t("forms.reqfield").toString();
      this.displaySnackbar(msg);
      return;
    }
    this.addUser();
  }
  async addUser() {
    var alreadyInGroup = this.newUsers.filter(x =>
      this.users.map(x => x.Email).includes(x),
    );

    if (alreadyInGroup.length === this.newUsers.length) {
      const msg = this.$t("snack.users.alreadyin").toString();
      this.displaySnackbar(msg);
      return;
    }
    if (alreadyInGroup.length > 0) {
      const msg = this.$t("snack.groups.alreadyin").toString();
      this.displaySnackbar(msg + alreadyInGroup.join(", "));
    }

    const data = {
      groupId: this.groupId,
      emails: this.newUsers,
    };
    this.$emit("invited", await this.inviteUsersAction(data));

    this.nextUser = "";
    this.newUsers = [];
  }
}
