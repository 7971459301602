import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VListItem,{class:{ pinned: _vm.isPinned },attrs:{"three-line":""},on:{"click":function($event){_vm.showDialog = true}}},[(_vm.isPinned)?_c(VListItemAvatar,[_c(VIcon,[_vm._v("mdi-pin")])],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.post.Title))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.post.Body))]),_c(VListItemSubtitle,[_c('span',[_vm._v(_vm._s(_vm._f("format")(_vm.post.CreatedAt)))])])],1),(_vm.isManager)?_c(VListItemAction,[_c('GroupPostMenu',{attrs:{"pinned-post-id":_vm.isPinned ? _vm.post.ID : 0,"group-id":_vm.groupId,"post":_vm.post}})],1):_vm._e()],1),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[(_vm.post.BannerImage)?_c('progressive-background',{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"pa-4",staticStyle:{"height":"200px"},attrs:{"src":_vm.post.BannerImage.URI},on:{"click":_vm.openImage}}):_vm._e(),_c(VCardTitle,[_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.post.Title))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.showDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardSubtitle,[_vm._v(_vm._s(_vm._f("format")(_vm.post.CreatedAt)))]),_c(VCardText,_vm._l((_vm.post.Body.split('\n')),function(p,i){return _c('div',{key:i,staticClass:"mb-2",staticStyle:{"width":"100%"},domProps:{"innerHTML":_vm._s(_vm.parseParagraph(p))}})}),0),(_vm.links.length > 0)?_c(VCardText,_vm._l((_vm.links),function(link,i){return _c('LinkPreview',{key:i,attrs:{"link":link}})}),1):_vm._e(),(_vm.files.length > 0)?_c(VCardText,[_c('span',{staticClass:"other--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("global.files"))+" ")]),_vm._l((_vm.files),function(file,i){return _c('FileDisplay',{key:i,attrs:{"file":file}})})],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }