









































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { EmailsInput } from "@/components/common";
import { Component, Prop } from "vue-property-decorator";
import { GroupUser, SentInvitation } from "@/core/models";
import { UploadUsersData } from "../../../../core/store/groups/users/actions";

@Component({ components: { EmailsInput } })
export default class UploadUsersCard extends Vue {
  @Prop({ default: () => -1 }) groupId!: number;
  @Prop({ default: () => [] }) users!: GroupUser[];

  @Getter("groups/addUsersLoading") addUsersLoading!: boolean;
  @Getter("groups/renameGroupLoading") renameGroupLoading!: boolean;
  @Action("groups/uploadExcel") addUsersFromFileAction!: (
    data: UploadUsersData,
  ) => Promise<SentInvitation[]>;
  @Action("displaySnackbar") displaySnackbar!: Function;

  file: File | null = null;
  adding = false;

  toggleAddUser() {
    this.adding = !this.adding;
  }

  handleFileChange(file: File) {
    if (
      !(
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    )
      return;
    this.file = file;
    console.log("handleFileChange: file: ", this.file.name);
  }

  submitAddUser() {
    if (!this.file) {
      const msg = this.$t("groups.missingexcel").toString();
      this.displaySnackbar(msg);
      return;
    }
    this.uploadUsers();
  }

  async uploadUsers() {
    if (!this.file) return;
    console.log("upload: file: ", this.file);
    const data: UploadUsersData = {
      groupId: this.groupId,
      file: this.file,
    };
    this.$emit("uploaded", await this.addUsersFromFileAction(data));
    this.file = null;
    // todo clear v-file-input
    this.toggleAddUser();
  }
}
