











































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { GroupVideo } from "@/core/models";

@Component
export default class GroupVideoMenu extends Vue {
  @Prop({ default: () => 0 }) groupId!: number;
  @Prop({ default: () => 0 }) pinnedVideoId!: number;
  @Prop({ default: () => ({} as GroupVideo) }) video!: GroupVideo;

  @Getter("groups/editVideoLoading") editVideoLoading!: string;
  @Getter("groups/deleteVideoLoading") deleteVideoLoading!: string;
  @Getter("groups/editedVideoID") editedVideoID!: string;
  @Getter("groups/deletedVideoID") deletedVideoID!: string;
  @Action("groups/editVideo") editVideoAction!: Function;
  @Action("groups/deleteVideo") deleteVideoAction!: Function;

  titleEdit = "";
  descriptionEdit = "";
  isPinnedEdit = false;
  editDialog = false;
  titleErrors: string[] = [];

  // Edit video
  @Watch("editDialog")
  toggleEdit() {
    if (this.editDialog) {
      this.titleEdit = this.video.Title;
      this.descriptionEdit = this.video.Description;
      this.isPinnedEdit = this.video.ID == this.pinnedVideoId;
    } else {
      this.titleEdit = "";
      this.descriptionEdit = "";
      this.isPinnedEdit = false;
    }
  }

  editVideo(): void {
    if (this.editVideoLoading) return;
    if (this.titleEdit.trim().length == 0) {
      this.titleErrors.push("Enter a title!");
      return;
    }
    let data = {
      groupId: this.groupId,
      videoId: this.video.ID,
      newTitle: this.titleEdit,
      newDescription: this.descriptionEdit,
      isPinned: this.isPinnedEdit,
    };
    this.editVideoAction(data);
    this.editDialog = false;
  }

  // Delete Video
  deleteVideo(): void {
    if (this.deleteVideoLoading) return;
    let data = {
      groupId: this.groupId,
      videoId: this.video.ID,
    };
    this.deleteVideoAction(data);
  }
}
