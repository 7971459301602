





























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class UsersSelect extends Vue {
  @Prop({ default: () => [] }) users!: string[];
  selectedUsers: string[] = [];

  toggleAll() {
    if (this.selectedUsers.length > 0) this.selectedUsers = [];
    else this.selectedUsers = this.users;
  }

  get icon() {
    if (this.selectedUsers.length === this.users.length) return "mdi-close-box";
    else if (this.selectedUsers.length > 0) return "mdi-minus-box";
    else return "mdi-checkbox-blank-outline";
  }

  @Watch("selectedUsers", { deep: true })
  onChange() {
    this.$emit("update", this.selectedUsers);
  }
}
