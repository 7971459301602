





























































































import Vue from "vue";
import { Group } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class CompanyGroupItem extends Vue {
  @Prop({ default: () => {} }) group!: Group;
  @Prop({ required: true }) courseName!: string;
  @Getter("groups/removeGroupLoading") removeGroupLoading!: boolean;
  @Getter("groups/removedGroupID") removedGroupID!: number;
  @Action("groups/removeGroup") removeGroupAction!: Function;
  @Action("groups/leaveGroup") leaveGroupAction!: (i: number) => Promise<void>;
  @Action("groups/joinGroup") joinGroupAction!: (i: number) => Promise<void>;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  joinDialog = false;
  removeDialog = false;
  joining = false;
  maxGroupUsersAvatars = 3;

  get userAvatars() {
    const users = this.group.Users.map(u => this.getInitials(u.Username));
    return users.slice(0, this.maxGroupUsersAvatars);
  }

  getInitials(fullName: string) {
    const allNames = fullName.trim().split(" ");
    return allNames.reduce((acc: string, curr: string, index: number) => {
      if (index === 0 || index === allNames.length - 1)
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      return acc;
    }, "");
  }

  getMoreUserCounter(usersCount: number) {
    const counter = usersCount - this.maxGroupUsersAvatars;
    if (counter > 100000 && counter < 1000000)
      return this.$n(counter, "compact", "en");

    return this.$n(counter, "compactFraction1", "en");
  }

  get owner() {
    const user = this.group.Users.find(x => x.IsOwner);
    return user ? user.Username : "";
  }

  // Delete group
  removeGroup() {
    if (this.removeGroupLoading) return;
    this.removeDialog = false;
    this.removeGroupAction(this.group.ID);
  }

  async joinGroup() {
    this.joining = true;
    try {
      const msg = this.$t("groupItem.groupJoined", {
        name: this.group.Name,
      }).toString();
      await this.joinGroupAction(this.group.ID);
      this.displaySnackbar(msg);
    } catch (error) {
      console.error(error);
    }
    this.joinDialog = false;
    this.joining = false;
  }
}
