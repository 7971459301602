




import Vue from "vue";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class AudioPlayer extends Vue {
  @Prop({ default: () => "" }) url!: string;
  plyr: Plyr = new Plyr("#plyr");

  @Getter("audio/total") total!: number;
  @Getter("audio/progress") progress!: number;
  @Getter("currentSession/length") sessionLength!: number;
  @Action("audio/updateTime") updateTime!: Function;
  @Action("audio/setTotalTime") setTotalTime!: Function;

  mounted() {
    this.plyr = new Plyr("#plyr", { invertTime: false });
    this.plyr.on("ready", () => {
      const duration = this.plyr.duration;
      if (this.sessionLength === 0 && duration !== 0)
        this.setTotalTime(duration);
    });
    this.plyr.on("timeupdate", () => {
      const time = this.plyr.currentTime;
      this.updateTime(time);
    });
  }

  @Watch("progress", { immediate: true })
  setProgress() {
    const time = this.progress * this.total;
    this.plyr.currentTime = time;
  }
}
