import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{attrs:{"ripple":""}},[_c(VListItemContent,[_c(VListItemTitle,[_c('i18n',{attrs:{"path":"groups.inv.message","tag":"span"},scopedSlots:_vm._u([{key:"inviter",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.inviter))])]},proxy:true},{key:"group",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.group))])]},proxy:true}])})],1),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.date))])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.accept}},[_c('span',[_vm._v(_vm._s(_vm.$t("groups.inv.accept")))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-check")])],1)],1),_c(VListItemAction,[_c(VBtn,{attrs:{"text":""},on:{"click":_vm.decline}},[_c('span',[_vm._v(_vm._s(_vm.$t("groups.inv.decline")))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }