



























































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component
export default class GroupName extends Vue {
  @Prop({ default: () => -1 }) groupId!: number;
  @Prop({ default: () => false }) isManager!: boolean;
  @PropSync("nameProp", { type: String }) name!: string;
  @Getter("groups/renameGroupLoading") loading!: boolean;
  @Action("groups/renameGroup") renameGroupAction!: Function;

  renamingGroup = false;
  newGroupName = this.name;
  groupNameRules = [
    (name: string) => {
      if (name.length < 3) return this.$t("forms.namelen3").toString();
      else return true;
    },
  ];
  updateName(e: any) {
    const {
      target: { textContent },
    } = e;
    this.name = textContent;
  }
  toggleRenameGroup() {
    if (this.renamingGroup) this.renamingGroup = false;
    else {
      this.renamingGroup = true;
      this.newGroupName = this.name;
    }
  }
  renameGroup() {
    if (this.loading) return;
    if (this.newGroupName.length < 3) return;
    if (this.newGroupName == this.name) return;
    let data = {
      groupId: this.groupId,
      newName: this.newGroupName,
    };
    this.renameGroupAction(data);
    this.toggleRenameGroup();
  }
}
