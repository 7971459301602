export const formatFromSeconds = (s: number) => {
  const hh = Math.floor((s / 3600) % 60);
  const mm = Math.floor((s / 60) % 60);
  const ss = Math.floor(s % 60);
  if (hh) return `${hh}h ${mm}m ${ss}s`;
  else if (mm) return `${mm}m ${ss}s`;
  else return `${ss}s`;
};

export const formatClockFromSeconds = (s: number) => {
  const mm = Math.floor((s / 60) % 60);
  const ss = Math.floor(s % 60);
  if (mm) return `${mm}:${ss}`;
  else return `00:${ss}`;
};

export const formatFromMilliSeconds = (ms: number) =>
  formatFromSeconds(ms / 1000);
