






























import Vue from "vue";
import { DateTime } from "luxon";
import { Action } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import api from "@/core/utils/api";
import { Group, GroupInvitation } from "@/core/models";

@Component
export default class SingleInvitation extends Vue {
  @Prop({ default: () => undefined }) invitation?: GroupInvitation;
  @Action("groups/addGroupToList") addGroupToList!: (g: Group) => void;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  get id() {
    return this.invitation?.id || 0;
  }
  get inviter() {
    return this.invitation?.inviter || "";
  }
  get group() {
    return this.invitation?.group || "";
  }
  get date() {
    if (!this.invitation) return null;
    const iso = this.invitation.createdAt;
    return DateTime.fromISO(iso).toRelative({ locale: this.$i18n.locale });
  }

  async accept() {
    try {
      const end = `/api/Groups/AcceptInvitation/${this.id}`;
      const group = Group.parseGroup(await api.get(end));
      this.addGroupToList(group);
      const msg = this.$t("groups.inv.accepted", {
        group: this.invitation?.group,
      }).toString();
      this.displaySnackbar(msg);
      this.$emit("remove");
    } catch (error) {
      console.log(error);
    }
  }

  async decline() {
    try {
      const end = `/api/Groups/DeclineInvitation/${this.id}`;
      await api.get(end);
      const msg = this.$t("groups.inv.declined", {
        inviter: this.invitation?.inviter,
      }).toString();
      this.displaySnackbar(msg);
      this.$emit("remove");
    } catch (error) {
      console.log(error);
    }
  }
}
