

















import Vue from "vue";
import Pages from "@/core/utils/Pages";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class PagesRender extends Vue {
  @Prop({ default: () => Pages.Empty() }) pages!: Pages<any>;
  id = 1;
  @Watch("pages")
  pagesChanged() {
    this.pages.setPage(0);
    this.id = 1;
  }
  setPage(page: number) {
    this.id = page;
    this.pages.setPage(page - 1);
  }
  mounted() {
    this.id = this.pages.CurrentPageId + 1;
  }
}
