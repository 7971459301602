







import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { LookupWord, LookupWordType } from "@/core/models/sessions";

@Component
export default class SingleWord extends Vue {
  @Prop({ default: () => ({} as LookupWord) }) word!: LookupWord;

  get color() {
    const { type } = this.word;
    switch (type) {
      case LookupWordType.stop:
        return "darkgray";
      case LookupWordType.filler:
        return "prezpRed";
      default:
      case LookupWordType.default:
        return "primary";
    }
  }
  get text() {
    return this.word.text;
  }
  get count() {
    return this.word.occurrences;
  }
  get showCount() {
    return this.word.occurrences > 1;
  }
}
