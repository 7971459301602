import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"value":_vm.momentFormatted,"error-messages":_vm.error,"disabled":_vm.disabled,"loading":_vm.loading,"label":_vm.label,"readonly":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"color":"primary","indeterminate":"","absolute":"","height":"2"}})]},proxy:true}],null,true)},on))]}}]),model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,{staticClass:"px-0 py-0"},[_c(VTabs,{attrs:{"fixed-tabs":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTab,{key:"calendar"},[_c(VIcon,[_vm._v("mdi-calendar")])],1),_c(VTab,{key:"timer",attrs:{"disabled":_vm.dateSelected}},[_c(VIcon,[_vm._v("mdi-clock")])],1),_c(VTabItem,{key:"calendar"},[_c(VDatePicker,{attrs:{"color":"primary","locale":_vm.$i18n.locale,"min":(_vm.minDate && _vm.minDate.toISOString()) ||
              new Date(Date.now()).toISOString(),"full-width":""},on:{"input":_vm.showTimePicker},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c(VTabItem,{key:"timer"},[_c(VTimePicker,{ref:"timer",staticClass:"v-time-picker-custom",attrs:{"format":"24hr","color":"primary","full-width":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"accent lighten-1","text":""},nativeOn:{"click":function($event){return _vm.clearHandler($event)}}},[_c('span',[_vm._v("Clear")])]),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.okHandler}},[_vm._v("Set")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }