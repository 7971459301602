









































































import Vue from "vue";
import { GroupPost } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class GroupPostMenu extends Vue {
  @Prop({ default: () => 0 }) groupId!: number;
  @Prop({ default: () => 0 }) pinnedPostId!: number;
  @Prop({ default: () => {} }) post!: GroupPost;

  @Getter("groups/editPostLoading") editPostLoading!: string;
  @Getter("groups/deletePostLoading") deletePostLoading!: string;
  @Getter("groups/editedPostID") editedPostID!: string;
  @Getter("groups/deletedPostID") deletedPostID!: string;
  @Action("groups/editPost") editPostAction!: Function;
  @Action("groups/deletePost") deletePostAction!: Function;

  bodyEdit = "";
  titleEdit = "";
  editDialog = false;
  isPinnedEdit = false;
  titleRules = [
    (title: string) => {
      if (title.trim().length == 0) return this.$t("forms.reqfield").toString();
      else return true;
    },
  ];
  bodyRules = [
    (body: string) => {
      if (body.trim().length == 0) return this.$t("forms.reqfield").toString();
      else return true;
    },
  ];

  // Edit post
  @Watch("editDialog")
  toggleEdit() {
    if (this.editDialog) {
      this.bodyEdit = this.post.Body;
      this.titleEdit = this.post.Title;
      this.isPinnedEdit = this.post.ID == this.pinnedPostId;
    } else {
      this.bodyEdit = "";
      this.titleEdit = "";
      this.isPinnedEdit = false;
    }
  }
  editPost() {
    if (this.editPostLoading) return;
    if (this.titleEdit.trim().length == 0 || this.bodyEdit.trim().length == 0)
      return;

    const data = {
      groupId: this.groupId,
      postId: this.post.ID,
      newTitle: this.titleEdit,
      newBody: this.bodyEdit,
      isPinned: this.isPinnedEdit,
    };
    this.editPostAction(data);
    this.editDialog = false;
  }

  // Delete Post
  deletePost() {
    if (this.deletePostLoading) return;
    const data = {
      groupId: this.groupId,
      postId: this.post.ID,
    };
    this.deletePostAction(data);
  }
}
