import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,{attrs:{"menu-props":{ bottom: true, offsetY: true },"label":_vm.$t('goals.assign'),"deletable-chips":"","items":_vm.users,"multiple":"","chips":""},scopedSlots:_vm._u([(_vm.users.length > 0)?{key:"prepend-item",fn:function(){return [_c(VListItem,{attrs:{"ripple":""},on:{"click":_vm.toggleAll}},[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.selectedUsers.length > 0 ? _vm.$t("goals.deselect") : _vm.$t("goals.select")))])],1)],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true}:null,{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-4 py-2"},[_vm._v(_vm._s(_vm.$t("global.nodata")))])]},proxy:true}],null,true),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})}
var staticRenderFns = []

export { render, staticRenderFns }