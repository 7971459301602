





































































import Vue from "vue";
import { GroupPost } from "@/core/models";
import GroupPostMenu from "./GroupPostMenu.vue";
import { urlRegex, getLinks } from "@/core/utils/getLinks";
import { FileDisplay } from "@/components/common";
import { LinkPreview } from "@/components/common";
import { Component, Prop } from "vue-property-decorator";

@Component({ components: { GroupPostMenu, FileDisplay, LinkPreview } })
export default class GroupPostItem extends Vue {
  @Prop({ default: () => 0 }) groupId!: number;
  @Prop({ default: () => {} }) post!: GroupPost;
  @Prop({ default: () => false }) isPinned!: boolean;
  @Prop({ default: () => false }) isManager!: boolean;

  showDialog = false;
  parseParagraph(p: string) {
    const matches = p.matchAll(urlRegex);
    for (const match of matches) {
      const url = match[0];
      let href = url;
      if (!href.startsWith("http")) href = "https://" + href;
      const aTag = `<a href="${href}" target="_blank">${url}</a>`;
      p = p.replace(url, aTag);
    }
    return p;
  }
  openImage() {
    window.open(this.post.BannerImage!.URI, "_blank");
  }
  get files() {
    return this.post.Files;
  }

  links: string[] = [];
  mounted() {
    this.links = getLinks(this.post.Body);
  }
}
