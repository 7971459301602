

































import Vue from "vue";
import { GroupPost } from "@/core/models";
import AddPostButton from "./AddPostButton.vue";
import GroupPostItem from "./GroupPostItem.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({ components: { AddPostButton, GroupPostItem } })
export default class GroupPostsCard extends Vue {
  @Prop({ default: () => 0 }) groupId!: number;
  @Prop({ default: () => 0 }) pinnedPostId!: number;
  @Prop({ default: () => false }) isManager!: boolean;
  @Prop({ default: () => [] }) allPosts!: GroupPost[];

  get pinnedPost() {
    return this.allPosts.find(p => p.ID == this.pinnedPostId);
  }
  get posts() {
    return this.allPosts
      .filter(p => p.ID !== this.pinnedPostId)
      .sort((a, b) => +b.CreatedAt - +a.CreatedAt);
  }
}
