import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VListItem,{class:{ pinned: _vm.isPinned },attrs:{"three-line":""},on:{"click":function($event){_vm.playDialog = true}}},[(_vm.isPinned)?_c(VListItemAvatar,[_c(VIcon,[_vm._v("mdi-pin")])],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"font-weight":"600"}},[_c('span',[_vm._v(_vm._s(_vm.video.Title))])]),_c(VListItemSubtitle,[_c('span',[_vm._v(_vm._s(_vm._f("format")(_vm.video.UploadedAt)))])]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.video.Description))])],1),(_vm.isManager)?_c(VListItemAction,[_c('GroupVideoMenu',{attrs:{"video":_vm.video,"group-id":_vm.groupId,"pinned-video-id":_vm.isPinned ? _vm.video.ID : 0}})],1):_vm._e()],1),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.playDialog),callback:function ($$v) {_vm.playDialog=$$v},expression:"playDialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.video.Title))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.playDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardSubtitle,[_vm._v(_vm._s(_vm._f("format")(_vm.video.UploadedAt)))]),_c(VCardText,_vm._l((_vm.video.Description.split('\n')),function(p,i){return _c('div',{key:i,staticClass:"mb-2",staticStyle:{"width":"100%"}},[_c('span',[_vm._v(_vm._s(_vm.parseParagraph(p)))])])}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }