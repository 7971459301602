import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.has)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h4 font-weight-bold mb-6"},[_c('span',[_vm._v(_vm._s(_vm.$t("groups.inv.title")))])]),_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,[_c('div',{staticClass:"d-flex"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","small":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.get}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.refresh")))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-refresh")])],1)],1),_c(VList,{staticStyle:{"overflow":"auto"},attrs:{"max-height":"300px"}},_vm._l((_vm.invitations),function(inv,i){return _c('SingleInvitation',{key:i,attrs:{"invitation":inv},on:{"remove":function () { return _vm.remove(i); }}})}),1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }