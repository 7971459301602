










import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SessionVideoPlayer extends Vue {
  @Prop({ default: () => "" }) url!: string;
  @Getter("audio/total") total!: number;
  @Getter("audio/progress") progress!: number;
  @Getter("currentSession/length") sessionLength!: number;
  @Action("audio/updateTime") updateTime!: Function;
  @Action("audio/setTotalTime") setTotalTime!: Function;

  video: HTMLVideoElement | null = null;

  mounted() {
    const id = "session_video_player";
    this.video = document.getElementById(id) as HTMLVideoElement;
    this.video.onload = () => {
      const time = this.video?.currentTime || 0;
      this.setTotalTime(time);
    };
    this.video.ontimeupdate = () => {
      const time = this.video?.currentTime || 0;
      this.updateTime(time);
    };
  }

  @Watch("progress", { immediate: true })
  setProgress() {
    const time = this.progress * this.total;
    if (!this.video) return;
    this.video.currentTime = time;
  }
}
