














































































import Vue from "vue";
import Pages from "@/core/utils/Pages";
import { GroupVideo, Group } from "@/core/models";
import { Component, Prop, Watch } from "vue-property-decorator";

import VideoMenu from "./VideoMenu.vue";
import PagesRender from "@/components/common/PagesRender.vue";
import VideoPlayer from "@/components/common/VideoPlayer.vue";

const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
@Component({ components: { PagesRender, VideoPlayer, VideoMenu } })
export default class VideosList extends Vue {
  @Prop({ default: () => {} }) group!: Group;

  get videos() {
    if (!this.group.Videos) return [];
    return this.group.Videos.sort((a, b) => +b.UploadedAt - +a.UploadedAt);
  }
  get isManager() {
    return this.group.IsManager;
  }

  limit = 3;
  dialog = false;
  pages = Pages.Empty();
  video: GroupVideo | null = null;
  selectVideo(item: GroupVideo) {
    this.dialog = true;
    this.video = item;
  }
  parseParagraph(p: string) {
    let matches = p.matchAll(urlRegex);
    for (let match of matches) {
      let url = match[0];
      let href = url;
      if (!url.startsWith("http")) href = "https://" + url;
      if (url.includes("@"))
        href = "https://mail.google.com/mail/?view=cm&fs=1&to=" + url;
      let tag = `<a href="${href}" target="_blank">${url}</a>`;
      p = p.replace(url, tag);
    }

    p = p.replaceAll("Uploaded by", this.$t("groups.uploaded_by").toString());
    return p;
  }

  @Watch("dialog", { immediate: true })
  deselectVideo() {
    if (this.dialog) return;
    this.video = null;
  }
  @Watch("videos", { immediate: true })
  makePages() {
    if (this.videos)
      this.pages = new Pages<GroupVideo>(this.limit, this.videos);
  }
}
