import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0","loading":_vm.loading,"disabled":_vm.loading}},[_c(VSubheader,[_c('span',{staticClass:"mr-6"},[_vm._v(" "+_vm._s(_vm.$t("groups.inv.sent"))+" ")]),(!_vm.searching)?_c(VSpacer):_c(VTextField,{model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}}),_c(VBtn,{attrs:{"icon":"","right":""},on:{"click":function($event){_vm.searching = !_vm.searching}}},[_c(VIcon,[_vm._v(_vm._s(_vm.searching ? "mdi-close" : "mdi-magnify"))])],1)],1),(_vm.invitations.length > 0)?_c(VCardText,{staticStyle:{"max-height":"300px","overflow":"auto"}},[(_vm.filtered.length > 0)?_c(VList,_vm._l((_vm.filtered),function(inv,i){return _c(VListItem,{key:i},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(inv.user))]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm._f("format")(inv.createdAt))+" ")])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":"","color":"prezpRed"},on:{"click":function($event){return _vm.remove(inv)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)],1)}),1):_c('div',[_vm._v(_vm._s(_vm.$t("global.nomatch")))])],1):_c(VCardText,[_vm._v(_vm._s(_vm.$t("groups.inv.nonesent")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }