import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0"}},[_c(VSubheader,[_c('span',{staticClass:"mr-6"},[_vm._v(_vm._s(_vm.$t("groups.users")))]),(!_vm.searching)?_c(VSpacer):_c(VTextField,{on:{"input":_vm.filterResults}}),_c(VBtn,{attrs:{"icon":"","right":""},on:{"click":_vm.toggleSearch}},[_c(VIcon,[_vm._v(_vm._s(_vm.searching ? "mdi-close" : "mdi-magnify"))])],1)],1),(_vm.filteredUsers.length > 0)?_c('GroupUsersList',{attrs:{"group-id":_vm.groupId,"is-manager":_vm.isManager,"filtered-users":_vm.filteredUsers}}):_c('div',{staticClass:"d-flex align-center justify-center pa-4"},[_c(VSubheader,[_c('span',[_vm._v(_vm._s(_vm.$t("groups.nousers", { term: _vm.filterTerm })))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }